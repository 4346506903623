$MENU: #E7CB95;
$MENU_SELECTED: #355C7D;

//$BROWN: rgb(203, 153, 126);
//$BEIGE_DARK: #E8D6CB;
$PINK_LIGHT: #D7806D;
$BEIGE_LIGHT: #F4EEE1;
//$GREY_LIGHT: rgb(240, 239, 235);
//$BROWN_LIGHT: rgb(221, 190, 169);
$GREEN_DARK: rgb(165, 165, 141);
//$GREEN_LIGHT: rgb(183, 183, 164);