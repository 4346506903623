@import "colours.module";

.container {

    .hero {
      background-image: url("../../resources/images/mow-bay2.jpg");
      height: 500px;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.6;
    }

  .cursive {
    font-family: 'Sacramento', cursive;
  }

  .table {
    width: 100%;
    padding: 15px 10%;
    text-align: left;
  }

  th {
    text-decoration: 2px underline $GREEN_DARK;
    width: 50%;
  }

  .table_half {
    width: 50%;
    padding: 15px 10%;
  }

  .row {
    padding-left:25%;
  }
}