.container {

  .title {
    display: flex;
    justify-content: flex-end;
  }

  .table {
    max-width: 100%;
  }
}