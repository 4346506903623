@import "src/styles.module";

.container {

  .cursive {
    font-family: 'Sacramento', cursive;
  }

  .registry_wrapper {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 36px;

    img {
      -webkit-filter: brightness(100%);
    }

    img:hover {
      -webkit-filter: brightness(70%);
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
    }
  }
}

@media screen and (max-width: $MOBILE_BREAKPOINT) {
  img {
    width: 100%;
  }
}