@import "src/colours.module";

.container {

  .cursive {
    font-family: 'Sacramento', cursive;
  }

  .upload_container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .image_label {
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid $MENU_SELECTED;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .image_input {
      visibility: hidden;
    }

    .gallery_image {
      max-height: 10px;
    }
  }

  .loading_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
