@import "src/styles.module";
@import "src/colours.module";

.container {

  .cursive {
    font-family: 'Sacramento', cursive;
  }

  .form_wrapper {
    max-width: 66%;
    display: flex;
    flex-direction: column;
    //margin: auto;

    .text_input {
      margin-bottom: 10px;
    }

    .text_input_long {
      margin-bottom: 10px;
    }

    @media screen and (max-width: 555px) {
      .text_input_long {
        div {
          padding-bottom: 20px;
        }
      }
    }

    @media screen and (max-width: 324px) {
      .text_input_long {
        div {
          padding-bottom: 36px;
        }
      }
    }

    .select_label {
      margin-top: 16px;
    }

    .select_input {
      margin-bottom: 10px;
      min-width: 150px;
    }

    .submit_button {
      border: 1px solid $MENU;
      margin: 16px 0;
    }

    .helperText {
      margin-top: -10px;
    }
  }
}