@import "src/colours.module";

.footer {
    height: 54px;
    margin-top: -54px;
    width: 100%;
    background-color: $PINK_LIGHT;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-decoration-line: none;
        color: $MENU;
    }
}