@import "src/styles.module";

.container {

  .hero {
    background-image: url("../../resources/images/north-coromandel.jpg");
    height: 500px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.6;
  }

  .cursive {
    font-family: 'Sacramento', cursive;
  }
}