@import "src/styles.module";

.container {
    min-height: 100%;
    width: 100%;
}

.content {
    margin: 0 $GUTTER_MARGIN;
    // header padding
    padding-top: 81px;
    // footer padding
    padding-bottom: 54px;
}

@media screen and (max-width: $MOBILE_BREAKPOINT) {
    .content {
        padding-top: 54px;
    }
}