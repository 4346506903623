@import "src/colours.module";
@import "src/styles.module";

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1200;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .09);
  padding: 18px 45px;
  background-color: $PINK_LIGHT;
  height: 81px;

  .links {
  }
}

.toolbar_inner {
  display: flex;
  justify-content: space-evenly;

  .home {
    display: inline;
    font-weight: 500;
    font-size: 36px;
    margin: 0 27px;
    cursor: pointer;
  }
}

.hamburger {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.drawer {
  background-color: $BEIGE_LIGHT!important;

  .drawer_icon {
    height: 4em;
    width: 4em;
  }

  drawer_text {
    color: red;
  }
}

.menu_item {
  color: $MENU;
  cursor: pointer;
  padding: 9px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menu_item:hover, .selected {
  color: $MENU_SELECTED;
}

@media screen and (max-width: $MOBILE_BREAKPOINT) {
  .toolbar {
    height: 54px;
  }
}