.container {

  .hero {
    background-image: url("../../resources/images/wharetana-bay.jpg");
    height: 500px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.6;
    margin-bottom: 36px;
  }

  .cursive {
    font-family: 'Sacramento', cursive;
  }
}